import React, { useRef, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

// import required modules
import { EffectCoverflow, Autoplay } from 'swiper/modules'
import { Platform } from './style'
const LazyLoad = require('react-lazyload').default

export default function App() {
  return (
    <>
      <Platform>
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          initialSlide={1}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          pagination={true}
          modules={[EffectCoverflow, Autoplay]}
          className="mySwiper"
        >
          <div className="p11">
            <SwiperSlide>
              <a href="https://www.okx.com/zh-hans/web3/discover" rel="nofollow">
                <LazyLoad>
                  <img src="/images/oyi.webp" alt="欧易 OKX 交易所" loading="lazy" />
                </LazyLoad>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://web3.bitget.com/en/dapp/searchresults" rel="nofollow">
                <LazyLoad>
                  <img src="/newimages/p1.webp" alt="p1" loading="lazy" className="pm1" />
                </LazyLoad>
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="https://dappbay.bnbchain.org/detail/g-token-tool" rel="nofollow">
                <LazyLoad>
                  <img src="/newimages/p2.webp" alt="p2" loading="lazy" />
                </LazyLoad>
              </a>
            </SwiperSlide>
          </div>
        </Swiper>
      </Platform>
    </>
  )
}
