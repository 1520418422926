import styled from 'styled-components'
export const Platform = styled.div`
  #app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }

  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 600px;
    border: 1px solid #888;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }
  .p11 {
    a {
      width: 25%;
      border-radius: 6px;
      margin: 0 10px;
      img {
        width: 100%;
        aspect-ratio: 706/272;
      }
    }
  }
  @media (max-width: 968px) {
    .swiper-slide {
      width: 250px;
    }
  }
`
