import { useTranslation } from '@pancakeswap/localization'
import Link from 'next/link'
import { isMobile } from 'react-device-detect'
import { Platform, OfficialWebsite, Function, Footer } from './style'
import AnimatedElements from './components/AnimatedElements'
const LazyLoad = require('react-lazyload').default
const Home: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation() // 多语言插件
  const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  }
  return (
    <>
      <div>
        <h1 style={{ display: 'none' }}>首页</h1>
        <Platform
          style={{
            backgroundImage: isMobile ? 'url(../../../newimages/641.webp)' : 'url(../../../newimages/671.webp)',
          }}
        >
          <div className="right">
            <img src="/newimages/banner.webp" alt="banner" />
          </div>
          <div className="left">
            <div>
              <img src="/newimages/platform.webp" alt="platform" loading="lazy" />
            </div>
            <div className="Mechanism">
              {t(
                'A decentralized pre-sale platform with Link new mechanism, including token creation, rune creation, IDO creation, mining, token pre-sale UltrLink comprehensive functions such as liquidity creation',
              )}
            </div>
            <div className="btn">
              <Link href="https://sol.ggg.dog/">
                <div className="create">{t('SOL Token Create')}</div>
              </Link>
              <Link href="https://help.ggg.dog/">
                <div className="learn">{t('learnMore')}</div>
              </Link>
            </div>
          </div>
        </Platform>

        <OfficialWebsite>
          <div>
            <div
              onClick={() => {
                window.location.href = 'https://mining.ggg.dog/'
              }}
            >
              <LazyLoad>
                <img src="/newimages/website.webp" alt="website" loading="lazy" />
              </LazyLoad>
            </div>
            <span
              onClick={() => {
                window.location.href = 'https://mining.ggg.dog/'
              }}
            >
              {t('One click generation of mining official website')}
            </span>
          </div>
          <div>
            <div
              onClick={() => {
                window.location.href = 'https://ido.ggg.dog/'
              }}
            >
              <LazyLoad>
                <img src="/newimages/presales.webp" alt="presales" loading="lazy" />
              </LazyLoad>
            </div>
            <span
              onClick={() => {
                window.location.href = 'https://ido.ggg.dog/'
              }}
            >
              {t('One click generation of IDO official website')}
            </span>
          </div>
          <div>
            <div
              onClick={() => {
                window.location.href = 'https://nft.ggg.dog/'
              }}
            >
              <LazyLoad>
                <img src="/newimages/nft.webp" alt="nft" loading="lazy" />
              </LazyLoad>
            </div>
            <span
              onClick={() => {
                window.location.href = 'https://nft.ggg.dog/'
              }}
            >
              {t('One click generation of NFT official website')}
            </span>
          </div>
        </OfficialWebsite>

        <Function>
          <div
            className="content"
            style={{
              backgroundImage: isMobile ? 'url(../../../newimages/644.webp)' : 'url(../../../newimages/672.webp)',
            }}
          >
            <div className="title11">
              <div>{t('major function ')}</div>
              <ul>
                <LazyLoad>
                  <img src="/newimages/function.png" alt="function" loading="lazy" />
                </LazyLoad>
              </ul>
            </div>
            <div className="describe">
              {t(
                'From creating tokens, managing tokens, to pre-sales, market value management, and trading, it covers the entire process of token sales.',
              )}
            </div>
            <div className="function">
              <div>
                <LazyLoad>
                  <img src="/newimages/token.webp" alt="token" loading="lazy" />
                </LazyLoad>
              </div>
              <div className="token">
                <div className="create">{t('Token-Factory')}</div>
                <p>{t('Supports bsc, ton, sol, eth, core, base, arbitrum, heco, pego and other blockchains')}</p>
                <ul>
                  <li>
                    <Link href="https://ton.ggg.dog/">{t('ton Token-Factory')}</Link>
                  </li>
                  <li>
                    <Link href="https://sol.ggg.dog/">{t('sol Token-Factory')}</Link>
                  </li>
                  <li>
                    <Link href="https://token.ggg.dog">{t('Multi-chain one-click currency issuance')}</Link>
                  </li>
                  <li>
                    <Link href="https://token.ggg.dog">{t('Publish ERC-314 with one click')}</Link>
                  </li>
                </ul>
                <LazyLoad>
                  <Link href="/tokenfactory">
                    <div className="line">
                      <img src="/newimages/line.png" alt="line" loading="lazy" />
                    </div>
                  </Link>
                </LazyLoad>
              </div>
            </div>
          </div>
          <div
            className="main"
            style={{
              backgroundImage: isMobile ? 'url(../../../newimages/645.webp)' : 'url(../../../newimages/673.webp)',
            }}
          >
            <div className=" first function">
              <div>
                <LazyLoad>
                  <img src="/newimages/presale.webp" alt="pre-sale" loading="lazy" />
                </LazyLoad>
              </div>
              <div className="token">
                <div className="create">{t('Token pre-sale')}</div>
                <p>{t('Various pre-sale forms, one-click generation of official website, quality upgrade')}</p>
                <ul>
                  <li>
                    <Link href="/createInscription">IDO</Link>
                  </li>
                  <li>
                    <Link href="/fairlaunch">{t('Pre-sale and super-raised pre-sale')}</Link>
                  </li>
                  <li>
                    <Link href="/createInscription">{t('Runes')}</Link>
                  </li>
                  <li>
                    <Link href="/mining">{t('Staking mining')}</Link>
                  </li>
                </ul>
                <LazyLoad>
                  <Link href="/fairlaunch">
                    <div className="line">
                      <img src="/newimages/line.png" alt="line" loading="lazy" />
                    </div>
                  </Link>
                </LazyLoad>
              </div>
            </div>
            <div className="function second">
              <div>
                <LazyLoad>
                  <img src="/newimages/swaps.webp" alt="swaps" loading="lazy" />
                </LazyLoad>
              </div>
              <div className="token">
                <div className="create">{t('Lock and Trade')}</div>
                <p>{t('Lock the tokens, lock the liquidity, and stabilize the market')}</p>
                <ul>
                  <li>
                    <Link href="/createlock">{t('Token Lock')}</Link>
                  </li>
                  <li>
                    <Link href="/createlock">{t('LP Lock')}</Link>
                  </li>
                  <li>
                    <Link href="/lplock">{t('Add liquidity')}</Link>
                  </li>
                  <li>
                    <Link href="/swap">{t('Swap')}</Link>
                  </li>
                </ul>
                <LazyLoad>
                  <Link href="/swap">
                    <div className="line">
                      <img src="/newimages/line.png" alt="line" loading="lazy" />
                    </div>
                  </Link>
                </LazyLoad>
              </div>
            </div>
            <div className=" function third">
              <div>
                <LazyLoad>
                  <img src="/newimages/robot.webp" alt="robot" loading="lazy" />
                </LazyLoad>
              </div>
              <div className="token">
                <div className="create">{t('Market Value')}</div>
                <p>{t('Come out with beautiful K-line and depth')}</p>
                <ul>
                  <li>
                    <Link href="https://sol.ggg.dog/#/market">{t('sol market value management')}</Link>
                  </li>
                  <li>
                    <Link href="https://ton.ggg.dog/marketValue">{t('ton market value management')}</Link>
                  </li>
                  <li>
                    <Link href="https://robot-nuu.vercel.app/#/marketbot">
                      {t('More chain market value management')}
                    </Link>
                  </li>
                  <li>
                    <Link href="https://sol.ggg.dog/#/batchTool/batchCreateWallet">
                      {t('Batch generation of wallets')}
                    </Link>
                  </li>
                  <li>
                    <Link href="https://sol.ggg.dog/#/batchTool/batchTransfer/SOL">{t('Batch transfer')}</Link>
                  </li>
                </ul>
                <Link href="/bot">
                  <div className="line">
                    <LazyLoad>
                      <img src="/newimages/line.png" alt="line" loading="lazy" />
                    </LazyLoad>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="advantage">
            <div className="title">
              {t('Our strengths')}
              <LazyLoad>
                <img src="/newimages/function.png" alt="function" loading="lazy" />
              </LazyLoad>
            </div>
            <ul>
              <li
                style={{
                  background: "url('/images/price.webp')",
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                  backgroundSize: '100% 50%',
                }}
              >
                <div className="Img">
                  <LazyLoad>
                    <img src="/images/priceLogo.webp" alt="priceLogo" loading="lazy" />
                  </LazyLoad>
                </div>
                <div className="price">{t('Super multifunctional')}</div>
                <div className="de">
                  {t(
                    'A complete set of functions for token sales: creating tokens, managing tokens, token pre-sales, market value management, token transactions, etc., supporting multiple chains',
                  )}
                </div>
              </li>
              <li
                style={{
                  background: "url('/images/func.webp')",
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                  backgroundSize: '100% 50%',
                }}
              >
                <div className="Img">
                  <LazyLoad>
                    <img src="/images/funcLogo.webp" alt="funcLogo" loading="lazy" />
                  </LazyLoad>
                </div>
                <div className="price">{t('Professional team')}</div>
                <div className="de">
                  {t(
                    '2 years of professional team to create a more solid and reliable blockchain platform to ensure the security of user transactions',
                  )}
                </div>
              </li>
              <li
                style={{
                  background: "url('/images/profession.webp')",
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                  backgroundSize: '100% 50%',
                }}
              >
                <div className="Img">
                  <LazyLoad>
                    <img src="/images/professionLogo.webp" alt="professionLogo" loading="lazy" />
                  </LazyLoad>
                </div>
                <div className="price">{t('appropriate price')}</div>
                <div className="de">
                  {t(
                    'Continuously optimize products, so that while the quality is upgraded, the price is also reduced to the most favorable, becoming the most cost-effective platform on the entire network',
                  )}
                </div>
              </li>
            </ul>
            <div className="advantage1" style={{ padding: 0, marginTop: isMobile ? 100 : 0 }}>
              <div className="title">
                {t('Partner')}
                <div>
                  <LazyLoad>
                    <img src="/newimages/function.png" alt="function" loading="lazy" />
                  </LazyLoad>
                </div>
              </div>
            </div>
            <AnimatedElements></AnimatedElements>
          </div>
        </Function>
        <Footer>
          <LazyLoad>
            <img src="/newimages/logo1.png" alt="logo1" loading="lazy" />
          </LazyLoad>
          <div>
            <ul>
              <li>
                <Link href="/tokenfactory">{t('Create Token')}</Link>
              </li>
              <li>
                <Link href="/createnft">{t('Create NFT')}</Link>
              </li>
              <li>
                <Link href="/managetokens">{t('Manage Tokens')}</Link>
              </li>
              <li>
                <Link href="/sendertoken">{t('Batch coin issuance')}</Link>
              </li>
              <li>
                <Link href="/increaseAddr">{t('Start fission')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link href="/idov2">{t('Create IDO')}</Link>
              </li>
              <li>
                <Link href="/idov2/list">{t('IDO Management')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link href="/createInscription">{t('Create runes')}</Link>
              </li>
              <li>
                <Link href="/createInscription/list">{t('Rune management')}</Link>
              </li>
              <li>
                <Link href="/inscription">{t('Batch Inscription')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link href="/launchpad">{t('Pre-sale list')}</Link>
              </li>
              <li>
                <Link href="/launchpad/create">{t('Create Link normal pre-sale')}</Link>
              </li>
              <li>
                <Link href="/fairlaunch/create">{t('Create Fairlaunch')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link href="/swap">{t('Swap')}</Link>
              </li>
              <li>
                <Link href="/liquidity">{t('Liquidity')}</Link>
              </li>
              <li>
                <Link href="/lplock">{t('Locked in liquidity')}</Link>
              </li>
              <li>
                <Link href="/lplock">{t('Unlocking liquidity')}</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link href="/bot">{t('Market Bot')}</Link>
              </li>
              <li>
                <Link href="https://robot-nuu.vercel.app">{t('Bot')}</Link>
              </li>
              <li>
                <Link href="/https://help.ggg.dog/">{t('Docs')}</Link>
              </li>
            </ul>
          </div>
        </Footer>
      </div>
    </>
  )
}

export default Home
