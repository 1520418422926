import styled from 'styled-components'
export const Platform = styled.div`
  background-size: 100% 100%;
  padding: 20px 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  .right {
    width: 49%;
    img {
      width: 100%;
      aspect-ratio: 682/626;
    }
  }
  .left {
    width: 49%;
    padding: 90px 0;
    div:nth-child(1) {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .Mechanism {
      font-size: 30px;
      line-height: 61px;
      margin-top: 86px;
    }
    .btn div:hover {
      cursor: pointer;
    }
    .btn {
      display: flex;
      margin-top: 124px;
      font-size: 30px;
      font-size: 30px;
      text-align: center;
      .create,
      .learn {
        width: 307px;
        line-height: 88px;
        background: #fb512d;
        border-radius: 15px;
        color: #000000;
      }
      .learn {
        margin-left: 34px;
        background: #d9ebeb;
      }
    }
  }
  @media (max-width: 968px) {
    display: block;
    .right {
      width: 100%;
      height: auto;
    }
    .left {
      padding: 50px 0;
      width: 100%;
      .Mechanism {
        width: 100%;
        text-align: center;
        font-size: 15px;
        margin-top: 20px;
        line-height: 30px;
      }
      .btn {
        margin-top: 20px;
      }
      .btn .create,
      .btn .learn {
        width: 153px;
        line-height: 44px;
        border-radius: 8px;
        font-size: 15px;
      }
    }
  }
`

export const OfficialWebsite = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #262626;
  padding: 40px;
  text-align: center;
  > div:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  > div {
    width: 30%;
    aspect-ratio: 660/330;
    position: relative;
    transition: transform 0.3s ease;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 22px;
      color: #cccccc;
      line-height: 76px;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
    }
  }
  @media (max-width: 968px) {
    box-sizing: border-box;
    display: block;
    padding: 50px 45px;
    > div {
      width: 100%;
      margin-bottom: 20px;
      span {
        font-size: 14px;
        line-height: 38px;
        height: 40px;
      }
    }
  }
`
export const Function = styled.div`
  @media (max-width: 968px) {
    .advantage1 {
      .title {
        font-size: 35px !important;
        div{
          width:42px !important;
          height:28px !important;
        }
      }
    }
    .content {
      padding: 12px;
      .title11 {
        position: relative;
        font-size: 35px !important;
        padding: 50px 0 !important;
        padding: 0;
        ul {
          margin: 0;
          width: 42px;
          height: 28px;
          margin: 0 0 0 10px;
        }
      }
      .describe {
        font-size: 14px !important;
        line-height: 30px !important;
        width: 70% !important;
      }

      .function {
        > div:nth-child(1) {
          width: 100%;
        }
        margin: 100px auto 0;
        padding: 0 30px 100px 30px;
        display: block !important;
        .token {
          width: 100%;
          margin-left: 0;
          text-align: center;
          .create {
            font-size: 23px;
            margin-top: 50px;
          }
          p {
            font-size: 13px;
            line-height: 23px;
            margin: 20px auto;
            width: 80%;
          }
          ul {
            width: 100%;
            display: flex;
            margin-top: 30px;
            flex-wrap: wrap;
          }
          ul li {
            background-size: 18px 18px;
            font-size: 16px;
            line-height: 36px;
            padding-left: 30px;
            margin-left: 10px;
          }
          .line {
            width: 62px;
            height: 62px;
            bottom: -84px;
            left: calc(50% - 31px);
            img {
              width: 20px;
              height: 6px;
              margin: 0;
            }
          }
        }
      }
    }
    .main {
      padding-top: 30px;
      .function {
        display: block;
        padding: 0 30px 100px 30px;
        margin: 50px auto 0;
        > div:nth-child(1) {
          width: 100%;
        }
        .token {
          margin-left: 0;
          text-align: center;
          // background:red;
          position: relative;
        }
        .create {
          font-size: 23px !important;
          margin-top: 80px !important;
        }
        p {
          font-size: 13px;
          line-height: 23px;
          margin: 20px auto;
          width: 100%;
        }
        ul {
          display: flex;
          margin-top: 30px;
          flex-wrap: wrap;
        }
        ul li {
          background-size: 18px 18px;
          font-size: 16px;
          line-height: 36px;
          padding-left: 30px;
          margin-left: 10px;
        }
        .line {
          width: 62px !important;
          height: 62px !important;
          bottom: -84px !important;
          left: calc(50% - 31px) !important;
          img {
            width: 20px;
            height: 6px;
            margin: 0;
          }
        }
      }
      .third,
      .first,
      .second {
        .token {
          width: 100% !important;
        }
      }
      .third,
      .first {
        border: 1px solid #4a4a4a !important;
      }
    }
    .advantage {
      padding-bottom: 30px !important;
      > .title {
        font-size: 35px !important;
        padding-top: 70px !important;
        img {
          margin: 0 0 20px 0;
          width: 42px !important;
          height: 28px !important;
        }
      }
      > ul {
        margin-top: 70px !important;
        padding: 0 30px;
        display: block !important;
        li {
          border-radius: 17px !important;
          margin: 70px 0;
          width: 100% !important;
          // >div:nth-child(1) {
          
          //   img{
          //     width:100%;
          //     height:100%;
          //   }
          // }
          .de {
            font-size: 13px !important;
            width: 80% !important;
            margin: 0px auto 100px !important;
          }
          .price {
            font-size: 25px !important;
            margin: 30px !important;
            margin-top: 91px !important;
          }
        }
      }
    }
  }

  // pc端
  .title11 {
    font-family: MF YueHei (Noncommercial);
    font-size: 70px;
    color: #ffffff;
    margin: 0 auto;
    padding: 183px 0 64px;
    text-align: center;
    display: flex;
    justify-content: center;
    > ul {
      width: 84px;
      height: 56px;
      margin: 0 0 0 29px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .Img{
    width:166px;
    height:158px;
    display:inline-block;
    margin-top:-65px;
    img{
      width:100%;
      height:auto;
    }
  }
  .describe {
    width: 36%;
    font-size: 28px;
    color: #ffffff;
    margin: 0 auto;
    line-height: 57px;
    text-align: center;
  }
  .main {
  overflow:hidden;
    background-image: url(../../../newimages/673.webp);
    background-size: 100% 100%;
    .first,
    .third {
      flex-direction: row-reverse;
      border: none;
    }
    .first {
      > div {
        width: 774px;
      }
    }
    .first,
    .second,
    .third {
      > .token {
        width: 50%;
      }
    }
  }
  .content {
    background-size: 100% 100%;
    overflow:hidden;
  }
  
  .pm1 {
    margin-right: 30px;
  }
  @media (max-width: 968px) {
    .pm1 {
      margin-right: 0px;
    }
    .Img{
      margin-top:-30px !important;
      width:75px;
      height:67px;
    }
  }

  .advantage1 {
    padding-bottom: 147px;
    /* background-image: url(../../../newimages/674.webp); */
    background-size: 100% 100%;
    .title {
      font-size: 70px;
      color: #fff;
      padding-top: 100px;
      display:flex;
      width:100%;
      justify-content:center;
      div{
        width:84px;
        height:56px;
        margin:0 0 20px 20px;
        img{
        width:100%;
        height:100%;
        }
      }
    }
  }
  .advantage {
    padding-bottom: 147px;
    background-image: url(../../../newimages/674.webp);
    background-size: 100% 100%;
    .title {
      font-size: 70px;
      color: #fff;
      padding-top: 100px;
      display:flex;
      justify-content:center;
    }
    ul {
      display: flex;
      justify-content: space-around;
      margin-top: 140px;
    }
    ul li {
      list-style: none;
      width: 28%;
      border-radius: 35px;
      text-align: center;
      border: 1px solid #4a4a4a;
      color: #fff;
      position: relative;
      // div:nth-child(1) {
      //   position: absolute;
      //   top: -55px;
      //   right: 0;
      //   // width: 100%;
      //   /* height: 406px; */
      }
      .price {
        font-size: 47px;
        color: #ffffff;
        margin: 183px 0 66px;
      }
      .de {
        width: 60%;
        font-size: 20px;
        line-height: 41px;
        margin: 0 auto 167px;
      }
    }
  }
  .function {
    width: 86%;
    padding: 40px;
    border-radius: 35px;
    border: 1px solid #666666;
    margin: 100px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #ffffff;
    > div:nth-child(1) {
      width: 45%;
      aspect-ratio:774/862;
    }
    .token {
      width: 45%;

      position: relative;
      ul li a:hover {
        color: #fc633c;
      }
      .line:hover {
        background: #fc633c;
        cursor: pointer;
      }
      .line {
        width: 137px;
        height: 137px;
        border-radius: 50%;
        border: 1px solid #4d4d4d;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
      .create {
        font-size: 46px;
        margin-top: 81px;
      }
    }
    p {
      font-size: 26px;
      margin-top: 46px;
      width: 65%;
      line-height: 46px;
      color: #999999;
    }
    ul {
      margin-top: 70px;
      width: 70%;
    }
    ul li:hover {
      background-image: url(/newimages/list1.png);
      color: #fc633c;
    }
    ul li {
      list-style: none;
      font-size: 32px;
      line-height: 73px;
      background-image: url(/newimages/list.png);
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 60px;
    }
  }
`
export const Footer = styled.div`
  background: #1c1c1c;
  font-size: 20px;
  color: #cccccc;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 112px 118px;
  img {
    width: 274px;
    height: 102px;
  }
  div {
    display: flex;
    justify-content: space-around;
    width: 80%;
  }
  ul > li {
    list-style: none;
  }
  @media (max-width: 968px) {
    div {
      width: 100%;
      margin-left: 10px;
      flex-wrap: wrap;
      justify-content: space-between;
      ul {
        width: 33%;
        margin-top: 10px;
      }
    }
    img {
      width: 136px;
      height: 50px;
    }
    padding: 25px;
    font-size: 13px;
    line-height: 30px;
    flex-wrap: wrap;
  }
`
